const languages = {
    "en": "us",
    "mr": "in",
    "hi": "in",
    "te": "in",
    "ur": "in",
    "gu": "in",
    "pa": "in",
    "ja": "jp",
    "de": "de",
    "es": "es",
    "pt": "pt",
    "fr": "fr",
    "zh": "cn",
    "id": "id",
    "it": "it",
    "ko": "kr",
    "ru": "ru",
    "ar": "ae",
    "af": "za",
    "zu": "za",
    "tr": "tr",
    "uk": "ua",
    "bg": "bg",
    "pl": "pl",
    "sv": "se",
    "nl": "nl"
};

// JavaScript program to calculate Distance Between
// Two Points on Earth
// From Geeks for Geeks

const calculateDistance = (lat1, lon1, lat2, lon2) => {

    // The math module contains a function
    // named toRadians which converts from
    // degrees to radians.
    lon1 = lon1 * Math.PI / 180;
    lon2 = lon2 * Math.PI / 180;
    lat1 = lat1 * Math.PI / 180;
    lat2 = lat2 * Math.PI / 180;

    // Haversine formula
    let dlon = lon2 - lon1;
    let dlat = lat2 - lat1;
    let a = Math.pow(Math.sin(dlat / 2), 2)
        + Math.cos(lat1) * Math.cos(lat2)
        * Math.pow(Math.sin(dlon / 2), 2);

    let c = 2 * Math.asin(Math.sqrt(a));

    // Radius of earth in kilometers. Use 3956
    // for miles
    let r = 6371;

    // calculate the result
    return (c * r);
}

export {languages, calculateDistance};
